import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import HydrationOverlay from './components/elements/HydrationOverlay/HydrationOverlay';
import DynamicNavigation from './components/navigation/DynamicNavigation';
import RootNavigation from './components/navigation/RootNavigation';
import { useControlTower } from './state/contexts/ControlTower/ControlTower';

export const router = createBrowserRouter([
  RootNavigation,
  DynamicNavigation
]);

const App = () => {
  const controlTower = useControlTower();
  
  if (!controlTower.hydration.isHydrated) return <HydrationOverlay />
  return <RouterProvider router={router} />
}

export default App;