import { FC, PropsWithChildren, useContext } from "react";
import { ControlTowerContext } from "./ControlTower.context";
import useMainMenu from "./useMainMenu";

interface ControlTowerProviderProps extends PropsWithChildren {};

const ControlTowerProvider: FC<ControlTowerProviderProps> = ({ children }) => {
  const mainMenuQuery = useMainMenu();
  
  const isHydrated = mainMenuQuery.isFetched;
    
  return (
    <ControlTowerContext.Provider value={{
      hydration: {
        isHydrated,
      },
      interface: {
        menus: {
          main: {items: mainMenuQuery.data || [],}
        }
      }
    }}>
      {children}
    </ControlTowerContext.Provider>
  )
}

const useControlTower = () => {
  const context = useContext(ControlTowerContext)
  if (!context) {
    throw new Error("useControlTower must be used within a ControlTowerProvider");
  }
  
  return context;
};

export { useControlTower };
export default ControlTowerProvider;