import { apiPlugin, storyblokInit } from "@storyblok/react";
import { Analytics } from "@vercel/analytics/react";
import React from 'react';
import ReactDOM from 'react-dom/client';
import { QueryClientProvider } from 'react-query';
import App from "./App";
import ButtonBlock from "./components/blocks/ButtonBlock/ButtonBlock";
import CalloutBlock from "./components/blocks/CalloutBlock/CalloutBlock";
import CardBlock from "./components/blocks/CardBlock/CardBlock";
import GridBlock from './components/blocks/GridBlock/GridBlock';
import ImageBlock from "./components/blocks/ImageBlock/ImageBlock";
import Page from './components/blocks/Page/Page';
import Paragraph from './components/blocks/Paragraph/Paragraph';
import PersonBlock from './components/blocks/PersonBlock/PersonBlock';
import TableBlock from "./components/blocks/TableBlock/TableBlock";
import Teaser from './components/blocks/Teaser/Teaser';
import reportWebVitals from './reportWebVitals';
import './scss/index.scss';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import ControlTowerProvider from "./state/contexts/ControlTower/ControlTower";
import { queryClient } from "./utils/funcs/vendors/TanStack/queryClient";

storyblokInit({
  accessToken: "jp0i0rgszSfUxFcqzRgmkQtt",
  use: [apiPlugin],
  components: {
    page: Page,
    teaser: Teaser,
    paragraph: Paragraph,
    grid: GridBlock,
    identity_member: PersonBlock,
    image: ImageBlock,
    Card: CardBlock,
    Table: TableBlock,
    Button: ButtonBlock,
    Callout: CalloutBlock,
  },
  enableFallbackComponent: true,
  customFallbackComponent: () => <></>,
  apiOptions: {
    resolveNestedRelations: true,
  }
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <ControlTowerProvider>
        <App />
      </ControlTowerProvider>
    </QueryClientProvider>
    <Analytics />
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
