import { FC, PropsWithChildren } from 'react';
import { MenuItem } from '../../../types/general';
import Footer from '../../basics/Footer/Footer';
import AsideMenu from '../../elements/AsideMenu/AsideMenu';

interface Props extends PropsWithChildren {
  showMenu?: boolean;
  showFooter?: boolean;
  menuItems?: MenuItem[];
};

const BaseLayout: FC<Props> = ({ children, showMenu = true, showFooter = true, menuItems = [] }) => {
  return (
    <div className="h-full flex">
      {showMenu && <AsideMenu items={menuItems} />}
      <div className="flex-1 flex flex-col">
        <div className="flex-1">
          { children }
        </div>
        {showFooter && (
          <div className="bg-gray-50 mt-8">
            <Footer />
          </div>
        )}
      </div>
    </div>
  )
}

export default BaseLayout;