import { StoryblokComponent, useStoryblok } from "@storyblok/react";
import classNames from "classnames";
import dayjs from "dayjs";
import { FC, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDebounce } from 'use-debounce';
import { useControlTower } from "../../../state/contexts/ControlTower/ControlTower";
import HydrationOverlay from "../../elements/HydrationOverlay/HydrationOverlay";
import BaseLayout from "../../layouts/BaseLayout/BaseLayout";
import styles from './ContentFromSlugPage.module.scss';

interface Props {};

const ContentFromSlugPage: FC<Props> = () => {
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const story = useStoryblok(location.pathname.replace("/", ""), { version: "draft" });
  const [isLoading] = useDebounce(!story?.content || loading, 2000);
  const ref = useRef(location.pathname);
  const controlTower = useControlTower();
    
  useEffect(() => {
    if (ref.current !== location.pathname) {
      setLoading(true);
    } else {
      setLoading(false);
    }
    ref.current = location.pathname;
  }, [story?.full_slug, location.pathname]);

  if (isLoading) {
    return (
      <BaseLayout showMenu={false} showFooter={false}>
        <HydrationOverlay />
      </BaseLayout>
    );
  }
  
  const headerImage = story.content.header_image as any;
  const hasHeaderImage = !!headerImage.filename;
  // const storyCreateDate = dayjs(story.created_at).format("DD MMM YYYY")
  const storyLastPublishDate = dayjs(story.published_at).format("DD MMM YYYY")
  
  return (
    <BaseLayout menuItems={controlTower.interface.menus.main.items}>
      <div className={classNames(styles.wrapper, hasHeaderImage && styles.withHeaderImage)}>
        <div className={styles.pageHeader}>
          {hasHeaderImage && (
            <div className={styles.headerImageWrapper}>
              <img src={headerImage.filename} alt="header" className={styles.headerImage} />
            </div>
          )}
          <div className={styles.pageHeaderContent}>
            <h2 className={styles.pageTitle}>{ story.name }</h2>
            <p className={styles.storyDate}>Laatst aangepast {storyLastPublishDate}</p>
          </div>
        </div>
        <div className={styles.content}>
          <StoryblokComponent blok={story.content} />
        </div>
      </div>
    </BaseLayout>
  );
};

export default ContentFromSlugPage;
