import classNames from 'classnames';
import { FC, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../../../assets/images/logo.png';
import { MenuItem } from '../../../types/general';
import Icon from '../../basics/Icon/Icon';
import styles from './AsideMenu.module.scss';
import AsideMenuItem from './AsideMenuItem/AsideMenuItem';

interface Props {
  items: MenuItem[];
};

const AsideMenu: FC<Props> = ({ items }) => {
  const [active, setActive] = useState(false);
  const [moveLogo, setMoveLogo] = useState(window.scrollY > 100);
  
  const openIfClosed = () => !active && setActive(true);
  const close = () => setActive(false);
  
  const detectScrollPosition = (e: Event) => {
    const treshold = 100;
    const scrollPosition = window.scrollY;
    if (scrollPosition > treshold) {
      setMoveLogo(true);
    } else {
      setMoveLogo(false);
    }
  };
  
  useEffect(() => {
    window.addEventListener('scroll', detectScrollPosition);
    return () => window.removeEventListener('scroll', detectScrollPosition);
  }, []);
  
  return (
    <>
      <div className={styles.triggerWrapper}>
        <button className={styles.trigger} onClick={openIfClosed}>
          <Icon name="menu-line" />
        </button>
      </div>
      <div onClick={openIfClosed} className={classNames(styles.wrapper, active && styles.active)}>
        <div className={styles.filler}> {/* Shifts content to make the collapse effect work properly */}
          <div className={styles.fillerPadding} />
        </div>
        <div className={styles.aside}>
          <div className={classNames(styles.logoWrapper, moveLogo && styles.moveLogo)}>
            <button className={classNames(styles.trigger, styles.triggerClose)} onClick={close}>
              <Icon name="close-line" />
            </button>
            <Link to="/">
              <div className={styles.logoBeta}>
                <img className={styles.emblem} src={logo} alt="Gouw Gent logo" />
              </div>
            </Link>
          </div>
          <menu onClick={close} className={styles.menu}>
            {items.sort((a, b) => a.position - b.position).map((item) => (
              <AsideMenuItem key={item.to} active={active} label={item.label} to={item.to} icon={item.icon || 'arrow-right-line'} />
            ))}
          </menu>
        </div>
        <div className={styles.backdrop} onClick={close} />
      </div>
    </>
  )
}

export default AsideMenu;