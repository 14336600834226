import normalizeUrl from "normalize-url";

const getLinkTypeProtocol = (type: string) => {
  return {
    email: `mailto:`,
    tel: `tel:`,
    url: '',
    story: `${window.location.origin}/`
  }[type]
}

export const storyblokUrlParser = (d: any): string => {
  const type = d.linktype;
  const protocol = getLinkTypeProtocol(type);
    
  return normalizeUrl([protocol, d.cached_url].join(''));
}