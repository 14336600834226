import { FC } from 'react';
import { ReactComponent as LogoMark } from '../../../assets/images/logo/mark.svg';
import LinearLoader from '../../basics/LinearLoader/LinearLoader';

interface Props {};

const HydrationOverlay: FC<Props> = () => {
  return (
    <div className="w-full h-full flex flex-col justify-center items-center">
      <div className="w-52">
        <LogoMark fill="currentColor" className="text-gray-300 mb-4 mx-auto" width={140} />
        <LinearLoader isLoading className="w-20 mx-auto" />
      </div>
    </div>
  )
}

export default HydrationOverlay;