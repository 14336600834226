import axios from "axios";
import { useQuery } from "react-query";
import { AvailableIcons } from "../../../components/basics/Icon/Icon.types";

interface MenuItems {
  name: string;
  full_slug: string;
  position: number;
  content: {
    icon: AvailableIcons;
    header_label: {
      value: string;
    };
  }
}
interface Response {
  stories: MenuItems[];
}

const ENDPOINT = 'https://api.storyblok.com/v2/cdn/stories?cv=1735050252&token=jp0i0rgszSfUxFcqzRgmkQtt&filter_query[in_header][is]=true&sort_by=position:asc'

const useMainMenu = () => {
  return useQuery({
    queryKey: ['menuItems'],
    queryFn: async () => {
      const response = await axios<Response>(ENDPOINT);
      return response.data;
    },
    select: (data) => {
      return data.stories.map((item) => ({
        label: !!item.content.header_label ? item.content.header_label.value : item.name,
        to: item.full_slug,
        icon: item.content.icon,
        position: item.position,
      }));
    }
  })
}

export default useMainMenu;