import { SbBlokData, StoryblokComponent, storyblokEditable } from '@storyblok/react';
import { FC } from 'react';

interface Props {
  blok: SbBlokData;
};

const GridBlock: FC<Props> = ({ blok }) => {  
  const items = blok.items as SbBlokData[];
    
  return (
    <div
      {...storyblokEditable(blok)} 
      key={blok._uid} data-test="grid"
    >
      <div className="max-w-4xl mx-auto">
        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-6">
          { items && items.map((nestedBlok) => (
            <div className="col-span-1" key={nestedBlok._uid}>
              <StoryblokComponent blok={nestedBlok} />
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default GridBlock;